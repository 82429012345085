// Color tokens reflected from the tokens in figma
// -----------------------------------------------------------------------------

$white: #fff;

$light--base: #4d4d4d;
$light--primary: #FFAC2D;
$light--primaryDark: #a66202;
$light--primaryLight: #FFD321;
$light--secondary: #20E381;
$light--teritary: #10CCB8;
$light--gray: #7a7979;
$light--darkGray: #444444;
$light--lightGray: #a6a4a4;
$light--black: #000;
$light--success: #2e7d32;
$light--warning: #f7b529;
$light--error: #f0453a;
$light--background: #FAFAFA;
$light--backgroundWhite: #fcfcfc;

$dark--base: #fff;
$dark--primary: #FFAC2D;
$dark--primaryDark: #8c5401;
$dark--primaryLight: #FFD321;
$dark--secondary: #20E381;
$dark--teritary: #10CCB8;
$dark--gray: #b5b3b3;
$dark--darkGray: #7a7979;
$dark--lightGray: #C0C0C0;
$dark--black: #fff;
$dark--success: #2e7d32;
$dark--warning: #f7b529;
$dark--error: #f0453a;
$dark--background: #1e1e1e;
$dark--backgroundWhite: #232323;