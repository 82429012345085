@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Breadcrumb";

#{$selector} {
    &--is-hidden {
        @include hidden()
    }

    &__list {
        @include centered-container();
        @include text("base");
        display: flex;
        padding: $space-xl $space-xl 0 $space-xl;
        list-style-type: none;
        color: $light--gray;
        flex-wrap: wrap;

        &--dark {
            color: $dark--gray;
        }

        &__item {
            @include text("headline/xs");
            &--is-hidden {
                @include hidden()
            }
        }

        &__seperator {
            padding: 0 $space-xs 0 $space-sm;
        }
    }
}
